.content{
    padding: 4.8%;
    width: 100%;
    background-color: #fff;
}
.content button {
    width: 100%;
  }

.content a {
    text-align: center;
    display: block;
}


.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
    flex-direction: column;
}
.logo  img {

    margin-right: 8px;
  }

.logo span {
    vertical-align: text-bottom;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 700;
  }

.logo h3{
    font-size: 24px;
    padding-bottom: 16px;
    text-align: center;
}


.logo h4{
    font-size: 15px;
    color: #2c2e2f;
    text-align: center;
}



.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
