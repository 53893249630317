.walletItem{
    cursor: pointer;
    padding: 12px 10px;
}

.walletItem.selected{
    cursor: initial;
    background-color: #1890ff;
    color: #fff;
}
.walletItem.selected .ant-list-item-meta-title{
    color: #fff;
}

.ant-layout-header {
    background-color: #f8f7f3;
    padding: 0.5rem 1rem;
    line-height: initial !important;
}
.ant-avatar{
    float: right;
}
.ant-layout-content{
    background-color: #fff;
    padding: 0.5rem 1rem;
}
.ant-descriptions-item-label{
    font-weight: bold;
}
.ant-divider-horizontal{
    margin: 0 0 24px 0;
}
.ant-list-item-extra{
    font-weight: bold;
    font-size: 0.9375rem;
}
.ant-list-footer{
    text-align: right;
}
